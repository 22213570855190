import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import AppContext from '../../contexts/AppContext';

import './index.css';

// Sections
import HeroSection from '../../components/CircleBackgroundHero';
import InfoSection from '../../components/innovationSections/InfoSection';
import WorkCard from '../../components/workCards/BigWorkCard';
import BlogsSection from '../../components/BestBlogs';

const pageTitle =
  'Innovation Consulting Services | Technology Innovation | Devetry';
const pageDescription =
  'Partner with Devetry for innovation consulting services. We can help you transform your organization, leverage technology, and grow your business.';

const InnovationPageTemplate = ({ blogs, work }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('services');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <main className="root-container innovation">
        <HeroSection
          title="Innovation"
          subtitle="The ability to innovate is the ability to succeed."
          body={
            <span>
              In order to succeed in today’s highly competitive landscape, you
              need to innovate. Not just consumer-facing products, but inside
              your organization and within your culture. Unfortunately,
              innovation is much easier said than done. Innovation takes
              strategic planning and consistent action to fully realize.
              <br />
              <br /> Devetry’s innovation consulting services take a holistic
              look at your organization and identify short-term and long-term
              strategies and programs. Whether you need to innovate and iterate
              on a complex software system or create a culture of trust and
              respect, our team can help!
            </span>
          }
          image="innovation-hero.svg"
        />
        <InfoSection />
        {work && (
          <div className="innovation-work">
            <WorkCard caseStudy={work} />
          </div>
        )}
        <BlogsSection items={blogs} title="Innovation Inspiration" />
      </main>
    </>
  );
};

const InnovationPage = ({ data }) => {
  const { edges } = data.blogs;
  const blogs = edges.map((e) => ({
    readingTime: e.node.fields.readingTime.text,
    slug: e.node.fields.slug,
    ...e.node.frontmatter
  }));
  const work = data.work.edges.length
    ? {
        slug: data.work.edges[0].node.fields.slug,
        ...data.work.edges[0].node.frontmatter
      }
    : null;

  return <InnovationPageTemplate blogs={blogs} work={work} />;
};

InnovationPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default InnovationPage;

export const pageQuery = graphql`
  query InnovationPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Innovation/index" } }) {
      frontmatter {
        templateKey
      }
    }
    work: allMarkdownRemark(
      filter: {
        frontmatter: {
          templateKey: { eq: "CaseStudy/index" }
          tags: { in: "Software" }
        }
      }
      limit: 1
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            cardImage {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
            cardTitle
            cardSubtitle
            tags
            heroLogo {
              src {
                childImageSharp {
                  fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
    blogs: allMarkdownRemark(
      filter: {
        published: { eq: true },
        frontmatter: {
          templateKey: { eq: "Blog/index" }
          category: { eq: "Development" }
        }
      }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
          }
        }
      }
    }
  }
`;

import React from 'react';
import { Link } from 'gatsby';


import './index.css';
import orangeEllipse from '../../../img/orange-ellipse.svg';
import workDots from '../../../img/work-dots.svg';
import { TButton } from '../../Button';
import arrowRight from '../../../img/arrow-right.svg';

export default () => {
  return (
    <section className="info-section">
      <div className="top secondary-padding">
        <img
          className="orange-ellipse"
          src={orangeEllipse}
        />
        <img
          className="dots"
          src={workDots}
          alt="Dots"
        />
        <div className="red-dot" />
        <div className="blog-left">
          <p className="c-title">DevOps</p>
          <p className="subtitle">
            Organizations that implement DevOps programs deploy 200+ times more
            often compared to traditional IT teams. This continuous integration,
            continuous delivery, and continuous deployment compound to reduce
            risk, increase efficiencies, and improve collaboration.
            <br />
            <br /> Devetry specializes in DevOps services and seeing your
            engineering organization from 30,000 feet. This unique perspective
            allows us to analyze, strategize, coach, and iterate the ideal
            DevOps program for your organization.
          </p>
          <Link to="/services/innovation/devops">
            <TButton text="Learn more about DevOps Services from Devetry" className="redirect-link"/>
          </Link>
        </div>
      </div>
      <div className="center secondary-padding">
        <img
          className="first-dots"
          src={workDots}
          alt="Dots"
        />
        <img
          className="second-dots"
          src={workDots}
          alt="Dots"
        />
        <div className="blog-left">
          <p className="c-title">Cloud & Data Architecture</p>
          <p className="subtitle">
            Improve flexibility and adaptability with modern data architecture.
            Devetry can help you analyze your customer’s needs and create an
            architecture that supports your current and future initiatives. We
            keep architectures simple, automated and aligned with your business
            and team while implementing value-added services like machine
            learning and disaster recovery.
          </p>
          <p className="c-title">Innovation Workshops</p>
          <p className="subtitle">
            Running periodic innovation workshops can help throughout your
            entire software development journey. From user research workshops to
            user testing and product feedback, Devetry works with all types of
            organizations to run innovation workshops.
            <br />
            <br />
            What can you expect to come from a workshop? Concrete, feasible
            ideas to grow your product, business, and revenue within a specific
            timeline. These workshops have the power to jumpstart ideas and put
            them into action.
          </p>
        </div>
      </div>
    </section>
  );
};
